.thumbNailView img {
    height: 60px;
    width: 60px;
    border-radius: 50%
  }

  .printable {
    display: none;
  }

@media print {

    #root {
       display: none;
    }
    
    .noPrint {
      display: none;
    }

    .printable {
      display: block;
    }

}

.toggleOn {
  background-color: #5cb85c !important;
  color: white !important;
}

.toggleInactive {
  background-color: white !important;
  color: white !important;
}

.toggleOff {
  background-color: #eeeeee !important;
  color: black !important;
}

.tileDiv {
  display: flex;
  flex-direction: row;
  background-color: #5cb85c;
  color: white;
  min-height: 70px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px; 
}

.MuiIconButton-root[aria-label="Profile"] {
  border-radius:0 !important;
}
